body.SixthFormHomePage {
	.homepageFooter {
		.footerLeft {
			.footerAddress {
				h4 {
					color:$SixthFormLink;
				}
				.contactIcon {
					&.phoneIcon {
					background-image:url(../images/SixthFormPhoneIcon.svg);
				}
				&.mailIcon {
					background-image:url(../images/SixthFormMailIcon.svg);
				}
				}
			}
		}
	}
	.bottomBorder {
			
    		background-image: url(../images/sixthFormHeaderBottomBorder.jpg);
    		
		}
}
.homepageFooter {
	width: calc(100% - 80px);
	padding:40px;
	display: flex;
	position:relative;
	.footerLeft {
		display:flex;
		width:100%;
		.footerLogo {
			width:120px;
			background-size: contain;
			background-position:center top;
			background-repeat: no-repeat;
		}
		.footerAddress {
			padding-left:20px;
			h4 {
				color:$LinkColor;
				font-weight:300;
				text-transform: uppercase;
				margin:0;
				font-size:18px;
				margin-bottom:10px;
			}
			.addressText {
				font-weight:300;
				margin-bottom:10px;
				a {
					vertical-align:top;
				}
			}
			.contactIcon {
				width:20px;
				margin-right:10px;
				height:20px;
				background-size:contain;
				background-position: center center;
				background-repeat: no-repeat;
				display: inline-block;
				&.phoneIcon {
					background-image:url(../images/phoneIcon.svg);
				}
				&.mailIcon {
					background-image:url(../images/mailIcon.svg);
				}
				&.twitterIcon {
					background-image:url(../images/twitterBlue.svg);
				}
				&.sixthFormTwitterIcon {
					background-image:url(../images/sixthFormTwitterIcon.svg);
				}
			}
		}
	}
	&.sixthFormFooter {
		.footerLeft {
			.footerAddress {
				h4 {
					color:$SixthFormLink;
				}
			}
		}
	}
	.footerRight {
		width:100%;
		#logoIcons {
			width:100%;
			text-align:right;
			
			
			ul {
				margin:0;
				padding:0;
				li {
					display: inline-block;
					list-style-type: none !important;
					img {
						height:60px;
					}
				}
			}
		}
		p {
			text-align:right;
			font-weight: 100;
			a {
				font-weight:400;
			}
		}
	}
	.bottomBorder {
		position: absolute;
		left:0;
		bottom:0px;
		width:100%;
		height:10px;
		background-image: url(../images/bottomBorder.jpg);
		background-size:contain;
		background-repeat:repeat-y;
		z-index:1;
	}
}
