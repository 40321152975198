.searchBox {
	width:40px;
	height:40px;
	position:fixed;
	background-color: $LinkColor;
	top:0px;
	right:30px;
	background-image:url(../images/search.svg);
	background-size:50% 50%;
	background-position:center 10px;
	background-repeat: no-repeat;
	z-index:5799;
}

#SearchForm_SearchForm {
	position:fixed;
	display:none;
	top:50%;
	left:50%;
	width:100%;
	max-width:450px;
	z-index:9999;
	@include transform(translateX(-49%) translateY(-50%));
	fieldset {
		border:none;

		#SearchForm_SearchForm_Search {
			position: absolute; 
			left:0;
			width: 300px;
			
			padding: 10px;
			border:1px solid transparent;
			background-color:transparent;
			color:#FFFFFF;
			font-style: italic;
			border: 0px;
			border-bottom:4px solid #FFFFFF;
			z-index: 8888;
			font-size:80px;
		}
		#SearchForm_SearchForm_action_results{
		    position: absolute;
		    color: #ddd;
		    @include transition($t1);
			@include appearance(none);
		    outline: 0 !important;
		    z-index: 9980;
		    font-family: WebSymbols;
		    text-decoration: none !important;
		    display: inline-block;
		    text-align: center;
		    cursor: pointer;
		    border: none !important;
		    margin-left: 20px;
		    font-size: 2em;
		    background-color: transparent;
		    right:0;
		    width:80px;
		    height:80px;
		    background-image:url(../images/searchIconWhite.svg);
		    background-repeat: no-repeat;
		    background-position: center center;
		    background-size:contain;
		    margin-top:30px;
		    &:hover{
		    	color: #fff;
		    }
		    i {
		    	color:#FFFFFF;
		    }
		}
	}
}
