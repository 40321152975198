#video-link {
	position: absolute;
	left:0;
	top: 50%;
	@include transform(translateY(-50%));
	display:inline-block;
	//z-index:1;
	
	.video-link-tab {
		position: absolute;
		@include transform(rotate(-90deg) translateX(100%));
		text-transform: uppercase;
		transform-origin: 29% 100%;
    	left: 0;
    	top:80%;
    	cursor:pointer;
    	background-color: #FFFFFF;
    	padding:1px;
    	.title {
    		padding:12px 20px;
    		background-color:$darkPurple;
    		color:#FFFFFF;
    		float:right;
    	}
		.tab-arrow {
			display: inline-block;
			color:$textDark;
			padding:12px 17px;
			@include transition($t1);
		}
	}
	ul {
		margin:0;
		padding:20px 20px 20px 80px;
		position: relative;
		left:-100%;
		border:1px solid #FFFFFF;
		background-color:#FFFFFF;
        @include transition($t1);
		bottom:51px;
	}
	li {
		max-width: 320px;
		width: 100%;
		display: block;
		align-items: center;
		text-align:left;
		padding: 10px 0;
		color: white;
		font-weight: 100;
        border-bottom:1px solid $LinkColor;
        .titleImage{
            background-image: url("../images/t-levels-logo.png");
            width: 100%;
            height: 90px;
            background-size: contain;
            background-repeat: no-repeat;
        }
		.titleText {
			display:block;
			color:$textDark;
			font-weight:400;
			&:hover {
				color:$LinkColor;
			}
		}
		
	}
	&.active {
		z-index: 9999;
		ul {
            left:0;
            
		}
		.video-link-tab {
			.tab-arrow {
				@include transform(rotate(180deg));
			}
		}
	}
}

// @media all and (max-height:800px){
// 	#video-link{
// 		&.active{
// 			ul{
// 				left: -100%;
// 			}

// 		}
// 	}
// }

// @media all and (max-width:800px){
// 	#video-link{
// 		&.active{
// 			ul{
// 				left: -100%;
// 			}

// 		}
// 	}
// }