body.SixthFormHomePage {
	.weekThoughtsWrapper {
		.weekThoughtsInner {
			.weekThoughtsItem {
				.TFTW {
					&:after {
						background-color:$SixthFormLink;
					}
				}
				.weekThoughtsAuthor {
					color:#FFFFFF;
				}
			}
		}
	}
}
.weekThoughtsWrapper {
	display: block;
	padding:40px;
	clear:both;
	.weekThoughtsInner {
		background-size:cover;
		background-position:center center;
		.weekThoughtsItem {
			background-color:rgba($textDark, 0.9);
			text-align:center;
			.TFTW {
				padding:10vh 0 40px 0;
				margin-bottom:40px;
				color:#FFFFFF;
				font-family:$font-garamond;
				font-size:60px;
				position: relative;
				&:after {
					content:'';
					position: absolute;
					bottom:0;
					left:50%;
					@include transform(translateX(-50%));
					width:160px;
					height:2px;
					background-color:$twitterBlue;
				}
			}
			.weekThoughtsTitle {
				color:#FFFFFF;
				font-weight: 400;
				font-size:40px;
				font-style: italic;
				margin-bottom:40px;
			}
			.weekThoughtsAuthor {
				font-weight:300;
				color:$twitterBlue;
				text-transform: uppercase;
				padding-bottom:10vh;
			}
		}
	}
}