/*** Mobile Screens ***/
#sidebar{
  width: 100%;
  /*margin-top: 40px;
  margin-bottom: 40px;*/
  float: right;
  position: relative;

  .Level-1{
    display: none;
    margin:0;
    padding:0;
    list-style-type: none;
    li { 
      list-style-type: none; 
      margin-left:0px;
    }
    ul{
    	display: none;
      clear: both;
      float: left;
      width: 100%;
    }
  }

  button{
    position: absolute;
    top: 0px;
    border: none;
    outline: none !important;
    right: 15px;
    cursor: pointer;
    width: 30px;
    height: 28px;
    @include transition($t1);
    background-color: transparent;
    font-size: 1.3em;
    color: $textDark;
    &:hover{
      color: $themeColor;
    }
  }
  .sidebar-heading{
    display: block;
    /*float: left;*/
    clear: both;
    width: 100%;
    position: absolute;
    left:0;
    top:0;
    @include transform(translateY(-100%))
    .title{
      padding: 20px 40px 20px 40px;
      display: block;
      text-decoration: none;
      color: #FFFFFF;
      font-size:30px;
      font-weight:300;
    }
  }
  ul{
    margin:0;
    padding:0;
    ul{
      display: none;

      li{
        a{
          /*** Level Indentation ***/
          padding-left:20px;
        }
      }

      ul{
        li{
          a{
            /*** Level Indentation ***/
            margin-left: 30px !important;
          }
        }
      }
    }
    li{
      clear: both;
      float: left;
      width: 100%;
      position: relative;
      a{
        display: block;
        padding: 5px 60px 5px 0px;
        text-decoration: none;
        @include transition($t1);
        position:relative;
        color: $textDark;
        @include transition($t1);
        font-weight:300;
        &:after {
          content:'→';
          position: absolute;
          right:20px;
          top:50%;
          @include transform(translateY(-50%))
          color:$textDark;
          @include transition($t1)
          opacity:0;
        }
        &:hover {
          &:after {
            right:0;
            opacity:1;
          }
        }
      }
    }
  }
}

#sidebar-inner{
  overflow: hidden;
  float: left;
  clear: both;
  width: calc(100% - 40px);
  padding:20px;
  position: relative;
  .sidebarInnerBG {
    position: absolute;
    width:100%;
    height:100%;
    left:0;
    top:0;
    opacity:0.05;

  }
}

/*** Desktop Screens ***/
@media all and (min-width: 768px) {
  #sidebar{
    .Level-1{
      display: block !important;
      margin-top: 0% !important;
    }
    ul{
      ul{
        display: none;
      }
    }
    .mainToggle:first-of-type{
      display: none  !important;
    }
  }
  .hideSideBarToggle{
    display: none;
  }
}
