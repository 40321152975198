.tutorGroups {
	display:block;
	padding:90px 30px;
	position: relative;
	background-size:cover;
	background-position: center center;
	&:after {
		content:'';
		position: absolute;
		left:0;
		top:0;
		width:100%;
		height:100%;
		background: -moz-linear-gradient(left, rgba(255,255,255,0.1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
		background: -webkit-linear-gradient(left, rgba(255,255,255,0.1) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,1) 100%);
		background: linear-gradient(to right, rgba(255,255,255,0.1) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1affffff', endColorstr='#ffffff',GradientType=1 );
		z-index:1;
	}
	.tutorGroupsWrapper {
		width:50%;
		margin-left:50%;
		position: relative;
		z-index:2;
		.tutorGroupsTitle {
			font-size:50px;
			color:$midGrey;
			font-family:$font-garamond;
			margin-bottom:60px;
		}
		.tutorGroupsHolder {
			
			a {
				display: inline-block;
				width:calc(50% - 4px);
				.tutorGroupsItem {
					border:2px solid $LinkColor;
					padding:30px;
					color:$LinkColor;
					width:calc(100% - 84px);
					margin:0 20px 20px 0;
					display:inline-block;
					float:left;
					font-size: 30px;
    				font-style: italic;
    				position: relative;
    				@include transition($t1);
    				text-align:left;
    				font-weight:400;
    				&:after {
    					content:'';
    					position: absolute;
    					right:20px;
    					top:50%;
    					@include transform(translateY(-50%));
    					width:60px;
    					height:60px;
    					background-image:url(../images/logoIcon3.png);
    					background-size: contain;
    					background-position: center center;
    					background-repeat: no-repeat;
    				}
    				&:hover {
    					background-color:$LinkColor;
    					color:#FFFFFF;
    				}
				}
			}
			
		}
		
	}
}