
	.vacancy__item {
		border-bottom: 1px solid $mainColor;
		clear:both;
		margin-bottom:60px;
		padding-bottom:60px;
		h4 {
			padding-top: 20px;
			font-weight: 300;
			span {
				font-weight: bold;
			}
		}
	}

