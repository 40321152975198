body.SixthFormHomePage {
	#welcomeMessage {
		.right {
			h4 {
				color:$SixthFormLink;
			}
			.content {
				&:after {
					background-color:$SixthFormLink;
				}
			}
		}
	}
}

body.TransitionPage {
	#welcomeMessage {
		padding:0;

		.left {
			display:none;
		}
		.right {
			width:100%;
			padding-left:0;
			padding-bottom:200px;
			.topTitle {
				color:$LinkColor;
				font-size:70px;
				text-transform: uppercase;
				font-family:$font-garamond;
			}
			.content {
				margin-bottom:0;
				padding:0;
				&:after {
					display:none;
				}
				&.contentBottom {
					padding-bottom:40px;
					margin-bottom:40px;
					&:after {
						content:'';
						position: absolute;
						left:50%;
						bottom:0;
						@include transform(translateX(-50%));
						width:200px;
						height:1px;
						background-color:$LinkColor;
						display:inline-block;
					}
				}
			}
			.welcomeIcon {
				height:100%;
				width:30%;
				right: -7vw;
			}
		}
	}
}
#welcomeMessage {
	padding: 50px;
	display:flex;
	.left{
		width:calc(100% / 3);
		background-size:cover;
		background-position:center center;
	}
	.right {
		width:calc(100% * (2 / 3) - 100px);
		text-align:center;
		padding:0 50px;
		position: relative;
		background-color:$veryLightGrey;
		.rightInner {
			display: inline-block;
			margin:0 auto;
			max-width:700px;
			width:100%;
			h4 {
				font-family:$font-garamond;
				font-size: 50px;
	    		font-weight: 300;
	    		color:$LinkColor;
			}
			.content {
				color:$textDark;
				padding:0 0 40px 0;
				position: relative;
				margin-bottom:40px;
				font-weight: 300;
				&:after {
					content:'';
					position: absolute;
					left:50%;
					bottom:0;
					@include transform(translateX(-50%));
					width:200px;
					height:1px;
					background-color:$LinkColor;
				}
				
				
			}

			.welcomeButton {
				display: inline-block;
				background-color:$LinkColor;
				color:#FFFFFF;
				text-transform: uppercase;
				padding:20px;
				border:2px solid $LinkColor;
				@include transition($t1)
				min-width:350px;
				&:hover {
					background-color:#FFFFFF;
					color:$LinkColor;
				}
			}
			.welcomeIcon {
				content:'';
				position: absolute;
				right:0;
				bottom:0;
				width:250px;
				height:250px;
				
				background-size:contain;
				background-position: right bottom;
				background-repeat: no-repeat;
				opacity:0.05;
				z-index:0;
			}
		}
		
	}
}