.welcomeLinksWrapper {
	padding:0 50px 60px 50px;
	position:relative;
	@include transition($t1);
	margin-top:-150px;
	float:left;
	.titleWrapper {
		max-width:1280px;
		margin:0 auto;
		position: relative;
		h4 {
			text-align: left;
			color:$textDark;
			display: inline-block;
			margin-bottom:60px;
			margin-top:0;
			font-size:50px;		
			font-family:$font-garamond;
			font-weight: 100;

		}
		.moreNewsTab {
			position: absolute;
			
			/*background-color:$LinkColor;*/
			
			
			text-transform: uppercase;
			
	    	right: 0;
	    	top:0;
	    	cursor:pointer;
	    	background-color: $textDark;
	    	padding:1px;
	    	display:inline-block;
	    	.moreNewsTitle {
	    		padding:12px 20px;
	    		background-color:#FFFFFF;
	    		color:$LinkColor;
	    		float:left;
	    	}
			.moreNewsTabArrow {
				background-color: $textDark;
				display: inline-block;
				color:#FFFFFF;
				padding:12px 15px;
				
			}
		}
	}
	.viewMoreBtn {
		padding:10px 30px;
		background-color:red;
		border-radius:10px;
		color:$LinkColor;
		display:inline-block;
		font-weight:bold;
		@include transition($t1);
		&:hover {
			background-color:$LinkColor;
			color:#FFFFFF;
		}
	}
	.outer {
		
		margin:0px auto 30px auto;
		.homepageNewsSlider {
			
			/**/
			margin:0 auto;
			display:block;
			z-index:200;

			.newsArticleWrapper {
				display:flex;
			}
			.slide {
				z-index:1;
				width:calc((100vw - 100px) / 5 - 2px);
				height:calc((100vw - 100px) / 5);
				background-color:#FFFFFF;
				display:inline-block;
				background-size:cover;
				background-position:center center;
				position:relative;
				overflow:hidden;
				box-sizing:border-box;
				&.article1 { border:none; .inner { background-color:rgba($LinkColor, 0.8); }}
				&.article2 { border:none; .inner { background-color:rgba(#f76500, 0.8); }}
				&.article3 { border:none; .inner { background-color:rgba(#dc0078, 0.8); }}
				&.article4 { border:none; .inner { background-color:rgba($twitterBlue, 0.8); }}
				&.article5 { border:none; .inner { background-color:rgba(#987fb8, 0.8); }}
				&.article6 { border:none; .inner { background-color:rgba(#606568, 0.8); }}
				&.article7 { border:none; .inner { background-color:rgba($LinkColor, 0.8); }}
				&.article8 { border:none; .inner { background-color:rgba(#f76500, 0.8); }}
				&.article9 { border:none; .inner { background-color:rgba(#dc0078, 0.8); }}
				&.article10 { border:none; .inner { background-color:rgba($twitterBlue, 0.8); }}
				
				&:hover {
					.inner {
						height:100%;
					}
				}
				.inner {
					/*margin:20px;*/
					
					padding:0px 20px;
					
					position:absolute;
					bottom:0;
					
					height:50%;
					width:calc(100% - 40px);
					@include transition($t1);

					.articleDate {
						/*margin-top:40px;*/
						height: 50%;
						display: block;
						position: relative;
						.date {
							position: absolute;
							bottom:0;
							display: inline-block;
							color:$textDark;
							/*padding:10px 0 20px 0;*/
							font-size:14px;
							
							font-weight:100;
							text-transform:uppercase;
							width:100%;
						}
					}
					.title {
						color:#FFFFFF;
						font-size:24px;
						display: block;
						position: relative;
						font-weight:300;
						text-align:center;
						position: absolute;
						left:50%;
						top:50%;
						font-family:$font-garamond;
						@include transform(translateX(-50%) translateY(-50%))

					}
					.content {
						margin-bottom:60px;
						
					}
					.readMore {
						border:1px solid $LinkColor;
						padding:10px 30px;
						display:inline-block;
						margin:30px 0 0 0;
						@include transition($t1);
						position:absolute;
						left:20px;
						bottom:30px;
						.italic {
							font-style: italic;
						}
						&:hover {
							background-color:$LinkColor;
						}
					}
				}
				section {
					margin-bottom:60px;
					p {
						text-align:justify;
					}
				}
				
			}
		}
	}
	.widgetNewsPrev, .widgetNewsNext {
		position: absolute;
		top:10px;
		display:inline-block;
		width:40px;
		height:40px;
		text-align:center;
		border:1px solid $lightGrey;
		z-index:8;
		background-size: 50% 50%;
		background-position: center center;
		background-repeat: no-repeat;
		@include transition($t1);
		a {
			color:$LinkColor;
			width: 100%;
		    height: 100%;
		    display: block;
		}
		&:hover {
			background-color:$LinkColor;
			border:1px solid $LinkColor;
		}
	}
	.widgetNewsPrev {
		right:70px;
		background-image: url(../images/leftBracketBlack.svg);
	}
	.widgetNewsNext {
		right:0px;
		background-image: url(../images/rightBracketBlack.svg);
	}
	&.hidden {
		opacity:0;
	}
}