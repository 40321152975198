header.pageHeader {
	
	height:600px;
}
body.SixthFormHomePage {
	header {
		nav.main {
			.navInner {
				.workWithUsWrapper {
					.workWithUs {
						background-color:$lightGrey;
						border:1px solid $lightGrey;
						.workWithUsText {
							color:$SixthFormLink;
						}
						.workWithUsArrow {
							&:after {
								color:$SixthFormLink;
							}
						}
					}
				}
				.backHome {
					display:none;
				}
			}
		}
		.bottomBorder {
			position: absolute;
    		left:0;
    		bottom:0px;
    		width:100%;
    		height:10px;
    		background-image: url(../images/sixthFormHeaderBottomBorder.jpg);
    		background-size:contain;
    		background-repeat:repeat-y;
    		z-index:1;
		}
    	
	}
}

body.TransitionPage {
	header {
		nav.main {
			position: relative;
		}
		.headerImagesWrapper {
			position: relative;
			width:100%;
			height: calc(100% - 170px);
			display:flex;
			.headImageOne,
			.headImageTwo,
			.headImageThree {
				width:100%;
				background-size:cover;
				background-position:center center;
			}
		}
	}
}

header {
	overflow:hidden;
	position:relative;
	height:100vh;
	background-size:cover;
	.headerImage {
		width:100%;
		height:100%;
		background-size:cover;
		position: relative;
		left:0;
		top:0;
	}
	nav.main {
		position:relative;
		top:0;
		z-index:2;
		width:calc(100% - 40px);
		width:100%;
		
		background-color:#FFFFFF;
		.showWeek{
			position: absolute;
			right:30px;
			top:-10px;
			background-color:$LinkColor;
			text-transform: uppercase;
			color:#FFFFFF;
			padding:10px 20px;
			font-weight: 100;
		}
		.navInner {
			width:100%;
			/*max-width:1280px;*/
			margin:0 auto;
			position:relative;
			display:flex;
			.logoWrapper {
				width:100%;
				text-align: center;
				padding:20px 0;
				@include order(2)
				.headerLogo {
					position: relative;
					width:250px;
					display:inline-block;
				}
			}
			.workWithUsWrapper {
				width:100%;
				text-align:right;
				padding:0 20px;
				@include order(3)
				.workWithUs, .backHome {
					border:1px solid $LinkColor;
					background-color:$LinkColor;
					display: inline-block;
					@include transition($t1)
					vertical-align: top;
					.workWithUsText {
						display: inline-block;
						padding:5px 10px;
						color:#FFFFFF;
						@include transition($t1);
						text-transform:uppercase;
					}
					.workWithUsArrow {
						display: inline-block;
						width:30px;
						height:30px;
						background-color:#FFFFFF;
						vertical-align: top;
						position: relative;
						&:after {
							content:'→';
							position: absolute;
							left:50%;
							top:50%;
							@include transform(translateX(-50%) translateY(-50%))
							color:$LinkColor;
							@include transition($t1)
							
						}
					}
					&:hover {
						border:1px solid $LinkColor;
						background-color:$LinkColor;
						.workWithUsText {
							color:#FFFFFF;
						}
					}
				}

			}
			.headerControls {
				position:relative;
				display: inline-block;
				text-align:left;
				vertical-align: top;
				width:100%;
				padding:0 20px;
				@include order(1)
				.socialIcon {
					padding:5px;
					
					background-color:#FFFFFF;
					display: inline-block;
					cursor:pointer;
					
						margin:0 2px;
						@include transition($t1);
						vertical-align: top;
					.iconImage {
						background-position: center center;
						background-size: contain;
						background-repeat: no-repeat;
						width:20px;
						height:20px;
					}
					&:hover {
						padding:20px 5px 5px 5px;
					}
					&.twitter {
						background-image: url(../images/twitterIcon.svg);
						
					}
					&.facebook {
						background-image: url(../images/facebookIcon.svg);
					}
					&.searchIcon {
						/*background-image: url(../images/searchIcon.svg);*/
						background-color:#606568;
					}
				}
			}
			
		}
		#desktopNavContainer {
				text-align:center;
				display: flex;
				max-width:100vw;
				#navigationBefore, #navigationAfter {
					width:50%;
					white-space: nowrap;
				}
				#desktopNav {
					display: flex;
					text-align:center;
					white-space: nowrap;
					width:100%;
					border-left:1px solid #FFFFFF;
					border-right:1px solid #FFFFFF;
					/*position: relative;*/
					z-index:2;
					.topLevel{
						/*position: relative;*/
						padding:8px 20px;
						display: inline-block;
						/*border-right:2px solid $LinkColor;*/
						&>a .checkOut {
							position: absolute;
							right:30px;
							top:-15px;
							width:200px;
							height:62px;
							@include transform(translateY(-100%) rotate(-40deg));
							background-image:url(../images/Transitionpage.png);
							background-size:contain;
							background-position: right bottom;
							background-repeat:no-repeat;
							opacity:0;
							@include transform-origin(100% 100%)
							@include animation(checkOutAnim 500ms linear 3s forwards)
						}
						a, span {
							color: #FFFFFF;
							text-transform: capitalize;
							font-weight:300;
							font-size:18px;
							position: relative;
						}
						.subMenu {
							/*padding-top:60px;*/
							position: absolute;
							left:0;
							bottom:0;
							width:100vw;
							display:none;
							@include transform( translateY(100%))
							z-index:4;
							background-color:#FFFFFF;
							.parent {
								width:100%;
								max-width: 1280px;
								margin:0 auto;
								padding:20px 0;
								white-space:normal;
								text-align:left;
								/*border-radius:5px;*/
								
								/*@include column-count(4);*/
								
								/*&:before {
									content:'';
									position: absolute;
									left:50%;
									top:0;
									@include transform(translateX(-50%) translateY(40px))
									width:40px;
									height:20px;
									background-color:rgba($LinkColor, 0.8);
									/*background-image: url(../images/dropdownTriangle.svg);
									@include clip-path(polygon(50% 0%, 100% 100%, 0% 100%));
									background-size:cover;
									background-position: center top;
									opacity:0.5;
									overflow:hidden;
								}*/
								.stack {
									text-align:left;
									width:calc(25% - 24px);
									margin: 0 10px;
									display: inline-block;
									
									a {
										color:$textDark;
										text-transform: capitalize;
										padding:5px 20px 5px 0;
										display:block;
										@include transition($t1)
										position: relative;
										&:after {
											content:'→';
											position: absolute;
											right:20px;
											top:50%;
											@include transform(translateY(-50%))
											color:$textDark;
											@include transition($t1)
											opacity:0;
										}
										&:hover {
											&:after {
												right:0;
												opacity:1;
											}
										}
									}
									.children {
										a {
											display:block;
										}
									}
								}
							}
						}
						&:hover {
							.subMenu{
								display:inline-block;
							}
						}
					}
				}
			}
	}
	.tagQuicklinkWrapper {
		position: absolute;
		left:50%;
		bottom:8vh;
		@include transform(translateX(-50%));
		z-index:2;
		width:100%;
		display:block;
		.tagline {
			color:#FFFFFF;
    		text-align:center;
    		font-size:50px;
    		font-family:$font-garamond;
			width:100%;
			box-sizing: border-box;
    		padding: 0px 50px;
    		display:block;
    		margin-bottom:60px;
		}
	}
	.pageHeader {
		background-color: #f4f4f4;
		width: 100%;
		height: 500px;
	}
	.flexslider {
		overflow:hidden;
		height:100%;
		.flex-viewport {
			height:100%;
			ul {
				overflow:hidden;
				height:100%;
				li {
					overflow:hidden;
					position:relative;
					width:100%;
					height:100%;
					.slideBG {
						background-position:center center;
						background-size:cover;
						margin-top: 0 !important;
						width:100%;
						height:100%;
					}
				}
			}
		}
		ul {
				overflow:hidden;
				height:100%;
				li {
					overflow:hidden;
					position:relative;
					width:100%;
					height:100%;
					.slideBG {
						background-position:center center;
						background-size:cover;
						margin-top: 0 !important;
						width:100%;
						height:100%;
					}
				}
			}
		
	}
	.bottomBorder {
			position: absolute;
    		left:0;
    		bottom:0px;
    		width:100%;
    		height:10px;
    		background-image: url(../images/bottomBorder.jpg);
    		background-size:contain;
    		background-repeat:repeat-y;
    		z-index:1;
		}
}

body.IE11 {
	header {
		nav.main {
			.inner.primary {
				#desktopNavContainer {
					#navigationBefore, #navigationAfter {
						width:30%;
					}
				}
			}
		}
	}
}

@keyframes checkOutAnim {
	from{
		opacity:0;
		@include transform(translateY(-100%) rotate(-40deg));
	}
	to {
		opacity:1;
		@include transform(translateY(-100%) rotate(0deg));
	}
}
