.scrollToTop {
	position: absolute;
	top: -40px;
	left: 50%;
	@include transform(translateX(-50%) translateY(-50%))
	background: #FFFFFF;
	padding: 10px 20px;
	font-size: 30px;
	z-index: 4000;
	cursor: pointer;
	color:$textDark;
	@include transition($t1)
	
	&:hover {
		background-color:$textDark;
		color:#FFFFFF;
	}
}

/*
|===============================================================
|	Scroll Top
|===============================================================
*/

.scrollToBottom {
	position: absolute;
	bottom: -10px;
	left: 50%;
	width: 50px;
	height: 50px;
	@include transform(translateX(-50%))
	background: #FFFFFF;
	padding: 10px;
	text-align:center;
	z-index: 4;
	cursor: pointer;
	color:$textDark;
	font-size:32px;
	@include transition($t1)
	&:hover {
		background-color:$textDark;
		color:#FFFFFF;
	}
}