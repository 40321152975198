body.SixthFormHomePage {
	#usefulLinks {
		.usefulLinksTab {
			
			.usefulLinksTitle {
				background-color:$SixthFormLink;
			}
		}
		ul {
			li {
				border-bottom:1px solid $SixthFormLink;
				.titleText {
					&:hover {
						color:$SixthFormLink;
					}
				}
			}
		}
	}
}

#usefulLinks {
	position: absolute;
	right:0;
	top:50%;
	@include transform(translateY(-50%))
	display:inline-block;
	z-index:2;
	
	.usefulLinksTab {
		position: absolute;
		@include transform(rotate(90deg) translateX(100%))
		/*background-color:$LinkColor;*/
		
		
		text-transform: uppercase;
		transform-origin: 100% 0%;
    	right: 0;
    	top:0;
    	cursor:pointer;
    	background-color: #FFFFFF;
    	padding:1px;
    	.usefulLinksTitle {
    		padding:12px 20px;
    		background-color:$LinkColor;
    		color:#FFFFFF;
    		float:left;
    	}
		.usefulLinksTabArrow {
			/*background-color: #FFFFFF;*/
			display: inline-block;
			color:$textDark;
			padding:12px 17px;
			@include transition($t1)
		}
	}
	ul {
		margin:0;
		
		padding:20px 80px 20px 20px;
		position: relative;
		right:-100%;
		border:1px solid #FFFFFF;
		background-color:#FFFFFF;
		@include transition($t1)
	}
	li {
		min-width: 200px;
		
		display: block;
		align-items: center;
		text-align:left;
		
		padding: 10px 0;
		color: white;
		font-weight: 100;
		border-bottom:1px solid $LinkColor;
		.titleText {
			display:block;
			color:$textDark;
			font-weight:400;
			&:hover {
				color:$LinkColor;
			}
		}
		
	}
	&.active {
		z-index: 9999;
		ul {
			right:0;
		}
		.usefulLinksTab {
			.usefulLinksTabArrow {
				@include transform(rotate(180deg));
			}
		}
	}
}
