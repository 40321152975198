#modal {
	position: fixed;
	width: 100%;
	height: 100vh;
	left:0;
	top:0;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color:rgba(0,0,0,0.5);
	z-index: 9999;
	.modalWrapper {
		padding: 40px;
		width: 500px;

		background: rgba(255,255,255,0.7);
		padding: 40px;
		text-align:center;
		h1 {
			color: $LinkColor;
		}
		.modalBox {
			
			width: 500px;
			/*height: 300px;*/
			section {
				height: 250px;
				overflow-y: auto;
				padding-right: 10px;
				box-sizing: border-box;
			}
			
			ul {
				li {
					height: 150px;
				}
			}
			h1 {
				color: $LinkColor;
			}
			h1, p {
				color: $LinkColor;
				text-align: center;
				a {
					color: $LinkColor;
				}
			}

			
		}
		.bx-viewport {
			margin-bottom: 20px;
		}

		.bx-pager {
			width: 100%;
			text-align: center;
			margin-top:40px;
			.bx-pager-item {
				position: relative;
				display: inline-block;
				margin: 0 5px;
				a.bx-pager-link {
					position: relative;
					display:block;
					width: 10px;
					height: 10px;
					border-radius: 50%;
					text-indent: -9999px;
					background: $LinkColor;
					opacity: 0.5;
				}
				a.bx-pager-link.active {
					opacity: 1;
				}
			}
		}

		.bx-controls-direction {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.bx-next{
			position: relative;
			display: inline-block;
			padding: 5px 38px 5px 10px;
			color: #FFFFFF !important;
			-webkit-transition: all 0.35s;
			transition: all 0.35s;
			text-transform: uppercase;
			background-color: #00539c;
			&:after {
				content: "→";
				position: absolute;
				right: 0;
				height: calc(100% + 1px);
				width: 29px;
				background: #fff;
				top: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				transform: translateY(-50%);
				color: rgb(0, 83, 156);
				transition: all 0.35s ease 0s;
			}
			&.disabled {
				display: none;
			}
		}
		.bx-prev{
			position: relative;
			width: 35px;
			height: 29px;
			&:after {
				content: "→";
				position: absolute;
				right: 0;
				height: calc(100%);
				width: 29px;
				background: #fff;
				top: 0;
				display: flex;
				justify-content: center;
				align-items: center;
				transform: rotate(180deg);
				color: rgb(0, 83, 156);
				transition: all 0.35s ease 0s;
			}
			&.disabled {
				display: none;
			}
		}

		.close {

			padding:10px 30px;
			text-align:center;
			display:block;
			border:2px solid $LinkColor;
			margin:30px auto 0 auto;
			background-color:transparent;
			color:$LinkColor;
			text-transform: uppercase;
			@include transition($t1);
			cursor:pointer;
			&:hover {
				background-color:$LinkColor;
				color:#FFFFFF;
			}
		}
	}
	
}

.priorityAlert {
	position:absolute;
	-webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    left:50%;
    top:50%;
    width:30%;
    min-width:240px;
    padding:40px;
    text-align: center;
    font-size:30px;
    background-color:$LinkColor;
    line-height:40px;
    color:#FFFFFF;
    z-index:1;
    .priorityAlert a {
		color:#FFFFFF;
	}

	.priorityAlertClose {
		display:block;
		width:100%;
		color:#FFFFFF;
		font-size:16px;
		line-height:40px;
		margin-top:30px;
		cursor:pointer;
	}
}

.priorityAlert a {
	color:#FFFFFF;
}

.priorityAlertClose {
	display:block;
	width:100%;
	color:#FFFFFF;
	font-size:16px;
	line-height:40px;
	margin-top:30px;
	cursor:pointer;
}
