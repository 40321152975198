/*
|===============================================================
|	Calendar Page Template Widget
|===============================================================
*/

.calendar-widget-table {
	width: 100%;
	padding-bottom: 50px;
	margin:0 0 2em;
	thead {
		tr {
			th {
				border: none;
    			padding: 15px 20px;
				
				    font-size: 2.8em;
				.show-month {
					display:inline-block;
					color: #FFFFFF;
					font-weight: bold;
					font-weight: lighter;
    				font-size: 35px;
    				font-family:$font-garamond;
					i {
						color: #fff;
					}
					.inner {
						color: #FFFFFF;
						width: auto;
					}
					
				}
				.next, .prev {
						width:20px;
						height:20px;
						margin:0 30px;
						.arrow {
							color:#FFFFFF;
						}
						
					}
			}
		}
	}
	.calendar-header {
		td.calendar-header-day, td {
			background: transparent !important;
			color:#FFFFFF;
			border:none !important;
		}
	}
	tbody{
		.calendar-day {
			/*border: 1px solid #ececec;*/
			/*color: #a9afb9;*/
			@include transition($t1);
		}
		tr {
			&:nth-child(even){ 
				background-color:transparent; 
			}
			td {
				padding: 20px 0;
				position: relative;
				text-align:center;
				.calEventPopup {
					position: absolute;
					top:40px;
					padding:20px;
					border:2px solid $LinkColor;
					background-color: $lightGrey;
					z-index: 2;
					    width: 180px;
					    text-align: center;
					    display:none;
					.dayTitle {
						color:$LinkColor;
						margin-bottom:20px;
						font-weight:500;
					}
					.eventLine {
						color:$LinkColor;
					}

				}
				&.hasEvent:hover {
					.calEventPopup {
						display:inline-block;
					}
				}
				&:nth-child(1) .calEventPopup { left:0; }
				&:nth-child(2) .calEventPopup { left:-20px; }
				&:nth-child(3) .calEventPopup { left:-40px; }
				&:nth-child(4) .calEventPopup { left:50%; @include transform(translateX(-50%)); }
				&:nth-child(5) .calEventPopup { right:-40px; }
				&:nth-child(6) .calEventPopup { right:-20px; }
				&:nth-child(7) .calEventPopup { right:0px; }
			}
		}
		.hasEvent:after {
			content:'';
			background-color:$textDark;
			width:7px;
			height:7px;
			position: absolute;
			left:5px;
			top:5px;
			border-radius:50%;
		}
		.today {
			color: #676b71;
		}
		
		.selected{color: #fff !important;}
		.out-of-month {
			background-color:transparent !important;
			opacity:0.2;
		}
	} 
	.calendar-day:hover, .selected {
		/*background-color: $LinkColor !important;*/
		color: #FFFFFF;
		font-weight: 500;
	}
}

.calendarWidget{
   /* padding: 10px;*/
}

/*
|===============================================================
|	Calendar Filter
|===============================================================
*/

.CalendarFilter{
    padding: 10px;
}

/* .CalendarFilter{
    span{
        display: inline-block;
        padding: 5px 0px;
        margin: 0px 5px;
        cursor: pointer;
    }
} */
.CalendarEvent #allEvents ul li,
.Calendar #allEvents ul li{
/*     display: none; */
}
/* .CalendarEvent.All #allEvents ul li,
.Calendar.All #allEvents ul li{
    display: block;
} */
.CalendarFilter span{
    cursor: pointer;
    display: inline-block;
    padding: 5px;
    color: #333 !important;
    border-bottom: 2px solid transparent;
    margin-bottom: 10px;
}
.CalendarFilter span.active{
    border-bottom: 2px solid $themeColor !important;
    color: $themeColor !important;
}
.noEvents{
    max-width: 500px;
    display: none;
    padding: 20px 10px;
    color: #666 !important;
    @include border-radius(3px);
    background-color: #eee;
}

#allEvents ul{
    max-width: 500px;
    margin: 0px !important;
    padding: 0px !important;
}
#allEvents ul li,
#HomepageEvents ul li{
    @include border-radius(5px);
    margin-bottom: 10px;
    background-color: $LinkColor ;
    float: left;
    width: 100%;
    clear: both;
    margin-left: 0;
	span.suffix {
		font-size: 12px;
		vertical-align: top;
		margin-left: 2px;
		line-height: 6px;
	}
    @include transition(background 0.35s);
    display: block;
    &:hover{
        background-color: #2472c5 !important;
    }
    .toggleBtn{
        cursor: pointer;
        padding: 10px;
        padding-left: 20px;
        color: #fff;
        *{
			color: #fff;
        }
		.arrow {
			position: absolute;
			top: 15px;
			right: 15px;
			color: #fff;
			
			// Not sure why animation($t1) did not workkj
		    -webkit-transition-duration: 0.8s;
			-moz-transition-duration: 0.8s;
			-o-transition-duration: 0.8s;
			transition-duration: 0.8s;

			-webkit-transition-property: -webkit-transform;
			-moz-transition-property: -moz-transform;
			-o-transition-property: -o-transform;
			 transition-property: transform;
		}
		&.active {
			.arrow {
				@include transform(rotate(180deg));
			}
		}
    }
}

.toggleContent{
	display: none;
	font-size: 0.8em;
	*{
		font-size: 1em;
		color: #fff !important;
	}
	.url{
		padding: 10px;
    	color: #333 !important;
    	background-color: #f7c832;
    	@include transition($t1);
    	&:hover{
    		background-color: #d92d2a !important;
    		color: #fff !important;
    	}
	}
}
.toggleBtn{
	cursor: pointer;
}

