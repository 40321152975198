.centerColumn {
	.centerRight {
		.news-teaser {
			margin-bottom:60px;
			clear:both;
			display:flex;
			h4 {
				margin-top:0;
				.galleryIcon {
					width:30px;
					margin-left:30px;
					margin-top:0;
				}
			}
			.newsHolderImage {
				display:inline-block;
				
				margin:0 20px 20px 0;
				.previewImage {
					width:200px;
					height:200px;
					display:inline-block;
					background-size:contain;
					background-position: center top;
					background-repeat: no-repeat;
				}
				.defaultImage {
					width:150px;
					height:150px;
					background-image: url(../images/newsDefault.jpg);
					background-repeat: no-repeat;
					background-position: center top;
					background-size: cover;
					display:inline-block;
				}
			}
			.newsHolderText {
				.content {
					width: calc(100% - 170px);
    				display: inline-block;
				}
				.button-hover {
					display:inline-block;
					margin-top:30px;
					color:$LinkColor;
				}
			}
			.readMore {
				&:hover {
					color:$LinkColor;
				}
			}
		}
		.contentWrapper {
			margin-bottom:60px;
		}
		.newsArticleImg {
			img {
				width:100%;
			}
		}

	}
	
}