/*
|===============================================================
|	Uploads
|===============================================================
*/

.uploadsWrapper {
	margin-top:60px;
}

.upload {
	position: relative;
	display: block;
	width: calc(100% - 55px);
	max-width: 500px;
	clear: both;
	
	padding: 15px 25px 15px 30px;
	border: 1px solid $themeColor;
	margin: 5px 0px;
	background-color: transparent;
	@include transition($t1);
	.uploadTitle {
		text-transform: uppercase;
		font-size: 1em;
	    display: inline-block;
	    /*padding-top: 5px;*/
	}
	a {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
	.fileIcon {
	    background-color:$themeColor;
	    display: inline-block;
	    float: right;
	    width: 25px;
	    height: 30px;
	    @include transition($t1);
	    position: absolute;
	    right:20px;
	    top:10px;
	    span {
	    	@include transition($t1);
	    	color: #fff;
		    background-color: #102d40;
		    height: 13px;
		    width: 100%;
		    padding-top: 2px;
		    font-size: 7px;
		    text-transform: uppercase;
		    display: block;
		    text-align: center;
		    margin-top: 8px;
	    }
	}

	&:hover {
		background-color: #eee  !important;
		color: #000 !important;
	}

	* {
		/*vertical-align: middle;
		font-weight: lighter !important;*/
	}
}
