/*
|===============================================================
|	Font Stack
|===============================================================
*/
$font-stack: 'Lato', sans-serif;
$font-garamond: 'EB Garamond', serif;

/*
|===============================================================
|	Color Variables
|===============================================================
*/
$textLight:                 #eeeeee;
$textDark:                  #606568;
$contentColor:              #000000;
$HeadingColor:              #00539c;
$LinkColor:                 #00539c;
$themeColor:                #00539c;
$lightGrey:                 #d5d1cf;
$textGrey:                  #858584;
$mainColor:                 #c6c6c6;
$mainColor2:                #444444;
$SixthFormLink:             #4d103b;
$midGrey:                   #606568;
$midLightGrey:              #e4e3e4;
$veryLightGrey:             #f9f8f7;
$twitterBlue:               #3ba5f7;
$YTpurple:                  #ae48ec;
$vimeoPink:                 #dc0078;
$ursOrange:                 #ff782e;
$blueGreen:                 #00b6d5;
$darkPurple:                #4c1e81;
 

// darken( $base-color, 10% );
// lighten( $base-color, 10% );
// saturate( $base-color, 20% );
// desaturate( $base-color, 20% );
// adjust-hue( $base-color, 20% );
//

/*
|===============================================================
|	Transition Variables
|===============================================================
*/
$t1:                      all 0.35s;
$t2:                      all  0.6s;

/*
|===============================================================
|	Transform Translate Variable
|===============================================================
*/
$center: translate(-50%,-50%);

/*
|===============================================================
|	Name: border-radius
|	Desc: Add a border radius to the element
|	@example scss - border-radius(3px)
|===============================================================
*/
@mixin border-radius($radius) {
     -webkit-border-radius: $radius;
        -moz-border-radius: $radius;
         -ms-border-radius: $radius;
             border-radius: $radius;
}

/*
|===============================================================
|	Name: box-shadow
|	Desc: Add box shadow to element
|   @param string $input
|	@example scss - box-shadow(10px 10px 5px #888888)
|===============================================================
*/
@mixin box-shadow($input) {
         -webkit-box-shadow: $input;
            -moz-box-shadow: $input;
             -ms-box-shadow: $input;
              -o-box-shadow: $input;
                 box-shadow: $input;  
}

/*
|===============================================================
|	Name: text-shadow
|	Desc: Add text shadow to element with text
|   @param string $input
|	@example scss - text-shadow(10px 10px 5px #888888)
|===============================================================
*/
@mixin text-shadow($input) {
        -webkit-text-shadow: $input;
           -moz-text-shadow: $input;
            -ms-text-shadow: $input;
             -o-text-shadow: $input;
                text-shadow: $input;
}

@mixin transition($transition) {
    -webkit-transition: $transition;
       -moz-transition: $transition;
        -ms-transition: $transition;
            transition: $transition;
}

@mixin transform($transform) {
      -webkit-transform: $transform;
         -moz-transform: $transform;
          -ms-transform: $transform;
              transform: $transform;
}

@mixin box-sizing($box-sizing) {
    -webkit-box-sizing: $box-sizing;
       -moz-box-sizing: $box-sizing;
        -ms-box-sizing: $box-sizing;
            box-sizing: $box-sizing;
}

@mixin background-cover($image) {
        background-position: center;
		background-image:$image;
		background-size: cover;
}

@mixin filter($filter) {
    -webkit-filter: $filter;
       -moz-filter: $filter;
        -ms-filter: $filter;
            filter: $filter;
}

@mixin stretchCover($background, $position, $index) {
       background-color: $background;
                 position: $position;
                     z-index: $index;
                        height: 100%;
                         width: 100%;
                           left: 0px;
                            top: 0px;
}

@mixin clip-path($input){
             -webkit-clip-path: $input;
                -moz-clip-path: $input;
                 -ms-clip-path: $input;
                  -o-clip-path: $input;
                     clip-path: $input;
}

@mixin column-count($input){
             -webkit-column-count: $input;
                -moz-column-count: $input;
                 -ms-column-count: $input;
                  -o-column-count: $input;
                     column-count: $input;
}

@mixin order($input){
             -webkit-order: $input;
                -moz-order: $input;
                 -ms-order: $input;
                  -o-order: $input;
                     order: $input;
}

/*
|===============================================================
|	Name: Center El
|	Desc: Used to center elements within parent element
|	@see transform
|	@example scss - centerEl()
|===============================================================
*/

@mixin centerEl() {
	position: absolute;
	top: 50%;
	left: 50%;
	@include transform($center)
}

/*
|===============================================================
|	Name: transform-origin
|	Desc: Reset transform origin of element
|   @param string $input
|	@example scss - transform-origin('top right')
|	@example scss - transform-origin('20% 50%')
|===============================================================
*/

@mixin transform-origin($input){
     -webkit-transform-origin: $input;
        -moz-transform-origin: $input;
         -ms-transform-origin: $input;
          -o-transform-origin: $input;
             transform-origin: $input;
}

/*
|===============================================================
|	Name: perspective
|	Desc: Set the perspective from where an element is viewed
|   @param string $input
|	@example scss - perspective(500px)
|===============================================================
*/

@mixin perspective($input){
          -webkit-perspective: $input;
             -moz-perspective: $input;
              -ms-perspective: $input; 
               -o-perspective: $input;
                  perspective: $input;
}
@mixin transform-style($input){ 
      -webkit-transform-style: $input;
         -moz-transform-style: $input;
          -ms-transform-style: $input;
           -o-transform-style: $input;
              transform-style: $input;
}
@mixin animation($input){
            -webkit-animation: $input;
               -moz-animation: $input;
                -ms-animation: $input;
                 -o-animation: $input;
                    animation: $input;
}
@mixin appearance($input){
             -webkit-appearance: none;
                -moz-appearance: none;
                 -ms-appearance: none;
                  -o-appearance: none;
                     appearance: none;
}

/*
|===============================================================
|	Name: flex
|	Desc: Adds Flexbox to element
|   @param string $input
|	@example scss - flex('center')
|===============================================================
*/

@mixin flex($input){
	  display: -webkit-flex;
	  display: -ms-flexbox;
	  display: flex;
	  webkit-align-items: $input;
	  -ms-flex-align: $input;
	  align-items: $input;
	  -webkit-justify-content: $input;
	  -ms-flex-pack: $input;
	  justify-content: $input;  
}

@mixin bezier-transform($input) {
  -webkit-transition-timing-function: $input;
  -moz-transition-timing-function: $input;
  -ms-transition-timing-function: $input;
  transition-timing-function: $input;
}

/*
|===============================================================
|	Responsive
|===============================================================
*/

$break-small: 767px;
$break-medium: 991px;
$break-large: 1024px;

/*
|===============================================================
|	Name: breakpoint
|	Desc: Adds responsive breakpoints to the website
|   @param string $media - Device / Can also been a pixel value
|   @param string $max - If min is pixel value, then add max value
|	@example scss - flex('center')
|===============================================================
*/

@mixin breakpoint($media, $max: '1199px') {
	@if $media == mobile {
		@media only screen and (max-width: $break-small) { @content; }
	}
	@else if $media == toTablet {
		@media only screen and (max-width: $break-medium) { @content; }
	}
	@else if $media == toDesktop {
		@media only screen and (min-width: $break-large) { @content; }
	}
	@else {
		@media only screen and (min-width: $media) and (max-width: $max) { @content; }
	}
}

.visible {
	display: block !important;
}
