body.SixthFormPage {
	#breadcrumbs {
		p {
			
			span {
				&.youAreHere {
					color: $SixthFormLink;
				}
			}
		}
	}
}
#breadcrumbs {
	border-bottom:1px solid $textDark;
	font-weight:100;
	padding: 10px 0;
	
	p {
		color: $textDark;
		font-weight:400;
		margin: 0;
		span {
			color: $textDark;
			font-weight:100;
			&.youAreHere {
				color: $LinkColor;
				text-transform:uppercase;
				font-weight:400;
			}
		}
		a {
			color:$textDark;
			font-weight:400;
		}
	}
}
