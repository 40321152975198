.leftColumn {
	display: block;
}

.centerColumn {
	width:100%;
	max-width:1280px;
	margin:0 auto;
	display:flex;
	ul li {
		list-style-type: disc;
		margin-left:40px;
	}
	ol li {
		list-style-type: decimal;
		margin-left:40px;
	}
	.centerRight {
		width:75%;
		display: inline-block;
		float: left;
		padding:0 10px 60px 90px;
		font-weight: 300;
		a {
			color:$LinkColor;
		}
		h1 {
			display: block;
			
			padding-bottom:10px;
			margin-bottom: 60px;
			font-family:$font-garamond;
			font-weight:300;
			font-size:60px;
		}
		ul {
			margin-bottom:20px;
			li {
				list-style-type: disc;
				margin-left:30px;
			}
		}
		.tutorGroups {
			.tutorGroupsWrapper {
				margin-left:0;
				width:100%;
				.tutorGroupsTitle {
					display:none;
				}
			}
		}
		table {
			thead, tbody {
				tr {
					td {
						padding:10px;
					}
				}
			}
		}
		.userform{
				.field {
					/*margin:30px 0;*/
					label {
						color:$LinkColor;
						/*font-family: $font-museo;*/
						font-weight: 100;
					}
					.text, .textarea {
						min-width:250px;
						width:100%;
						padding:10px 10px 10px 0;
						/*border: 1px solid $darkBlue;*/
						margin-top:10px;
						.middleColumn {
							input.text, textarea.textarea {
								padding:10px;
							}
						}
					}
					
				}
				.checkboxset {
					legend {
						color: $LinkColor;
						font-weight: 100;
					}
					.middleColumn {
						.checkboxset {
							border:1px solid $lightGrey;
							list-style-type: none;
							li {
								
								margin-left:0px;
								list-style-type: none;
								padding:0 !important;
								&:before, &:after {
									display:none;
								}
								.checkbox {
									display:none;
									&:checked {
										&+ label {
											background-color:$LinkColor;
											color:#FFFFFF;
										}
									}

								}
								label {
									padding:10px !important;
									display:block;
								}
							}
						}

					}
				}
				.optionset {
					legend {
						color: $LinkColor;
						font-weight: 100;
					}
					.middleColumn {
						.optionset {
							
							list-style-type: none;
						    max-height: 40vh;
    						overflow-y: scroll;
							li {
								padding:10px;
								margin-left:0px;
								list-style-type: none;
								&:before {
									display:none;
								}
								&:after {
									content:'';
									border:1px solid $LinkColor;
									position: absolute;
									left:10px;
									top:0%;
									border-radius: 0;
									width:20px;
									height:20px;
									@include transform(translateX(0%) translateY(50%) !important)
									background-color:transparent;
									pointer-events:none;
								}
								.radio {
									display:none;
									&:checked {
										+label:before {
											content:'';
											position: absolute;
											left:0px;
											top:10PX;
											border:1px solid $LinkColor;
											width:20px;
											height:20px;
											background-color:$LinkColor;
											color:#FFFFFF;
											pointer-events:none;
										}
									}

								}
								label {
									padding:10px 10px 10px 30px;
									position: relative;
									    width: calc(100% - 50px);
									
								}
								&:hover:after {
									background-color:rgba($LinkColor, 0.5);
								}
							}
						}

					}
				}
				.file {
					.middleColumn {
						.file {
							padding:10px;
							border-radius:0;
							border:1px solid $lightGrey;
							color:$LinkColor;
						}
					}
				}
				.action {
					min-width:250px;
					width:100%;
					padding:10px;
					border: 1px solid $LinkColor;
					border-radius:0;
					background-color:transparent;
					margin-bottom:30px;
					/*color:$darkBlue;*/
					@include transition($t1);
					&:hover {
						background-color:$LinkColor;
						color:#FFFFFF;
					}
				}
			}
			form {
				fieldset {
					border:none;
					padding:0;
					.text, .textarea {
						/*margin-bottom:20px;*/
						label {
							margin-bottom:10px;
							color:$LinkColor;
						}
						.middleColumn {
							input, textarea {
								padding:10px 20px;
								width:100%;
								max-width:300px;
								font-size:18px;
								margin-top:10px;
								color:$LinkColor;
								
								border:2px solid $LinkColor;
							}
						}
					}
				}
				span.error{
						   display: inline-block;
						   /*float: left;*/
						   clear: both;
						   padding: 10px 20px;
						   margin-bottom: 20px;
						   border: 1px solid #c70000;
						   color: #c70000;
						   background-color: #ffe7e7;
						}
				.Actions {
					.action {
						padding:10px 20px;
						margin-top:20px;
						width:100%;
						max-width:328px;
						border:2px solid $LinkColor;
						color:$LinkColor;
						text-align:center;
						@include transition($t1);
						background-color:transparent;
						font-size:18px;
						text-transform: uppercase;
						&:hover {
							background-color:$LinkColor;
							color:#FFFFFF;
						}
					}
				}
			}
		.contentObject {
			margin-bottom:10px;
			.title {
				display: block;
				background-color:$LinkColor;
				padding:20px;
				color:#FFFFFF;
				font-weight:400;
				cursor:pointer;
				@include transition($t1);
				position: relative;
				&:hover {
					background-color:#000000;
				}
				
			}
			.contentWrapper {
				display:none;
			}
			&:nth-child(7n+1) {
				.title {
					background-color:$LinkColor;
				}
			}
			&:nth-child(7n+2) {
				.title {
					background-color:$twitterBlue;
				}
			}
			&:nth-child(7n+3) {
				.title {
					background-color:$YTpurple;
				}
			}
			&:nth-child(7n+4) {
				.title {
					background-color:$vimeoPink;
				}
			}
			&:nth-child(7n+5) {
				.title {
					background-color:$ursOrange;
				}
			}
			&:nth-child(7n+6) {
				.title {
					background-color:$blueGreen;
				}
			}
			&:nth-child(7n+7) {
				.title {
					background-color:$darkPurple;
				}
			}
		}
		#SearchResults {
				margin:0;
				padding-left:0;
			li {
				margin-bottom:30px;
			}
		}
		#PageNumbers {
			margin-bottom:60px;
		}
	
		.contentWrapper {
			margin-bottom:60px;
		}
		
		

	}
	.centerLeft {
		width:25%;
		display: inline-block;
		/*padding: 0 10px;*/
		
		max-width:330px;
		min-width:250px;
		
	}
}