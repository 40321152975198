.centerColumn {
	.centerRight {
		.contentWrapper {
			margin-bottom:60px;
		}
		.NewsletterItem {
			width:calc(33% - 64px);
			min-width:180px;
			border:2px solid $LinkColor;
			padding:20px;
			display:inline-block;
			margin:0 20px 20px 0;
			@include transition($t1);
			.newsletterThumb {
				width:100%;
				height:280px;
				background-position: center center;
				background-size: contain;
				background-repeat: no-repeat;
				border:2px solid #000000;
			}
			.newsletterTitle {
				width: 100%;
				display:block;
				text-align:center;
				margin-top:20px;
				@include transition($t1);
			}
			&:hover {
				background-color:rgba($LinkColor, 0.6);
				.newsletterTitle {
					color:#FFFFFF;
				}
			}
		}
	}
	
}