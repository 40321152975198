.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  z-index: 2000;
  position: fixed;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 5px;
  width: 200px;
  z-index: 9999;
  background: #fff;
  border: 1px solid #29d;
  overflow: hidden;
}

.pace .pace-progress {
	@include box-sizing(border-box);
	@include transform(translate3d(0, 0, 0));
	max-width: 200px;
	position: fixed;
	z-index: 2000;
	display: block;
	position: absolute;
	top: 0;
	right: 100%;
	height: 100%;
	width: 100%;
	background: #29d;
}

.pace.pace-inactive {
  display: none;
}

/*** Page Loading ***/
.Loading{
	@include transition(opacity 0.35s);
	opacity: 1;
	@include stretchCover(#bedff7, fixed, 9000);
}
.pace-running{
	overflow: hidden;
}

/*** Page Loading Complete ***/
.pace-done{
	.Loading{
		height: 0%;
		opacity: 0;
	}
}

/*** HomePage ***/
.banner{
	position: relative;
	overflow: hidden;
	float: left;
	width: 100%;
	height: 100vh;
	@include background-cover(url("../images/bg.jpg"));

	.darken{
		@include stretchCover(rgba(43,43,43, 0.5), absolute, 1)
	}

	.bg{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		@include background-cover(url("../images/bg.jpg"));
	}
}

#home1{
	padding: 100px 0px
}

/*** Page ***/
.Page{
	.banner{
		height: 50vh;
	}
}

/*** Footer ***/
footer{
	padding: 30px 0px;
}

/*** Footer ***/
footer{
	padding: 30px 0px;
}