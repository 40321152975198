body.SixthFormHomePage {
	#homepage-events {
		
		.widgetsWrapper {
			.left {
				.calendar-widget-table {
					thead {
						tr {
							th {
								.show-month {
									/*color:$SixthFormLink;*/
								}
								.prev, .next {
									.arrow {
										color:$SixthFormLink;
									}
									&:hover{
										background-color:rgba($SixthFormLink,0.6);
									}
								}
							}
						}
					}
					tbody {
						tr {
							td.calendar-header-day {
								color:$SixthFormLink;
							}
							td.calendar-day {
								&:before {
									
									border:1px solid $SixthFormLink;
								}
								
							}
						}
					}
				}
			}
		
			.right {
				.sliderControls {
					.allEvents {
						color:$SixthFormLink;
						&:hover {
							background-color:$SixthFormLink;
							border:1px solid $SixthFormLink;
							color:#FFFFFF;
						}
					}
					#eventsNext, #eventsPrev {
						&:hover {
							background-color:$SixthFormLink;
						}
					}
				}
				#sliderUpcoming {
					.dateContainer {
						.dateContainerInner {
							border:1px solid $SixthFormLink;
							.dateInfo {
								.day {
									color:$SixthFormLink;
								}
							}
							.dayItemWrapper {
								.dayItem {
									.title {
										a {
											color:$SixthFormLink;
										}
									}
									.dayTime {
										.eventIcon {
											
											background-image:url(../images/sixthFormAlarmClock.svg);
											
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.homepageEventsSliderWrapper, #homepage-events {
	position: relative;
	/*float: left;*/
	background-size:cover;
	background-position:center 40%;
	padding:90px 30px 60px 30px;
	background-attachment: fixed;
	width: calc(100% - 60px);
	background-color:$veryLightGrey;
	.backgroundOverlay {
		position:absolute;
		left:0;
		top:0;
		width:100%;
		height:100%;
		background-size:cover;
		background-position:center center;
		opacity:0.1;
		&:after {
			content:'';
			position: absolute;
			left:0;
			top:0;
			width:100%;
			height: 100%;
			background: -moz-linear-gradient(left, rgba(249,248,247,0) 0%, rgba(249,248,247,1) 50%);
			background: -webkit-linear-gradient(left, rgba(249,248,247,0) 0%,rgba(249,248,247,1) 50%);
			background: linear-gradient(to right, rgba(249,248,247,0) 0%,rgba(249,248,247,1) 50%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00f9f8f7', endColorstr='#f9f8f7',GradientType=1 );
		}
	}

	
		.widgetsWrapper {
			
			width:100%;
			max-width:1280px;
			margin:0 auto;
			display:flex;
			position: relative;
			z-index: 2000;
				.calendar-widget-table {
					font-family:$font-stack;
					padding-bottom:0;
					position: relative;
					thead {
						background-color: transparent;
						th {
							background-color: transparent;
							text-align:left;
							/*position: relative;*/
							padding:0;
							border-bottom:8px solid $midLightGrey;
							.show-month {
								padding:0;
							}
							.prev, .next {
								position: absolute;
								bottom:0;
								@include transform(translateY(100%));
								
								width:calc(50% - 40px);
								
								background-color: $midLightGrey;
								
								color:$textDark;
								padding:12px 20px;
								@include transition($t1);
								text-transform:uppercase;
								font-size:20px;
								font-weight:100;
								margin:0;
								.arrow {
									color:$LinkColor;
								}
								.inner {
									opacity:0;
								}
								&:hover {
									background-color:rgba($LinkColor,0.6);
									color:#FFFFFF;
								}
							}
							.prev {
								left:0;
								text-align:right;

							}
							.next {
								right:0;
								text-align:left;
								
							}
						}
					} 
					tbody {
						tr {
							background-color:transparent;
							td.calendar-header-day {
								background-color:transparent;
								color:$LinkColor;
								border:none;
								font-family: $font-stack;
								padding:15px 0;
								text-transform: uppercase;

							}
							td.calendar-day {
								text-align:center;
								color:$textDark;
								font-family:$font-garamond;
								font-size: 21px;
    							font-weight: 100;
    							position: relative;
    							&:before {
    								content:'';
    								position: absolute;
    								left:0;
    								top:0;
    								width:100%;
    								height:100%;
    								border:1px solid $LinkColor;
    								opacity:0;
    								@include transition($t1)
    							}
								&.hasEvent:hover .calEventPopup {
									display:none;
								}
								&:hover {
									&:before {
										opacity:1;
									}								
								}
							}
						}
					}
				}
			
			.left{
				/*float:left;*/
				h4 {
					/*margin-left:20px;*/
					color:$textDark;
					font-family:$font-garamond;
					font-size: 50px;
					font-weight:100;
				}
			}
			.left, .right {
				width:50%;
				display: inline-block;
				/*float:left;*/
				padding:60px;
				/*height:600px;*/
				
				
			}
			.right {
				position: relative;
				.sliderControls {
					/*position:relative;*/
					height:60px;
					margin-bottom:20px;
					.allEvents {
						padding:7px 60px 7px 30px;
						height:24px;
						border:1px solid $textDark;
						display:inline-block;
						position: absolute;
						right:60px;
						bottom:0;
						/*background-color:#FFFFFF;*/
						color:$LinkColor;
						font-weight:300;
						text-transform: uppercase;
						&:hover {
							background-color:$LinkColor;
							border:1px solid $LinkColor;
							color:#FFFFFF;
						}
						&:after {
							content:'→';
							position: absolute;
							top:0;
							right:0;
							height: calc(100% - 8px);
							width:40px;
							background-color: $textDark;
							color:#FFFFFF;
							text-align: center;
    						padding: 8px 0 0 0;
						}
					}
					#eventsNext, #eventsPrev {
						position: absolute;
						bottom:0px;
						display:inline-block;
						width:40px;
						height:30px;
						text-align:center;
						color:#FFFFFF;
						background-color:$textDark;
						z-index:9999;
						padding-top:10px;
						a {
							color:$LinkColor;
							width:100%;
							height:100%;
							display:block;
							position: absolute;
							left:0;
							top:0;
						}
						&:hover {
							background-color:$LinkColor;
							
						}
					}
					#eventsPrev {
						left:60px;
					}
					#eventsNext {
						left:105px;
					}
				}
			}
		}
	#sliderUpcoming {
		width:100%;
		max-width:1280px;
		margin:0 auto;
		.eventItem {
			padding-bottom:10px;
			.sliderContent {
				background-color:$LinkColor;
				padding:15px;
				font-family: $font-stack;
				.title, .date {
					width: 100%;
				}
				.title {
					a {
						font-weight:300;
					}
				}
				.date {
					display: inline-block;
					font-weight: 700;
					font-size: 14px;
				}
				&:hover{
					background-color: #000000;
					color:$LinkColor;
					.date {
						color:$LinkColor;
					}
				}
			}
		}
		
		#eventsInner {
			z-index:1;
			section {
				margin-bottom:60px;
				.eventsContent {
					text-align: justify;
				}
			}
		}
		.dateContainer {
			padding-bottom:20px;
			.dateContainerInner {
				width:calc(100% - 22px);
				max-height:280px;
				overflow-y:scroll;
				border:1px solid $LinkColor;
				/*margin:0 0 20px 0;*/
				padding:10px 20px 10px 0;
				display:flex;

				.dateInfo {
					min-width:100px;
					text-align:center;
					padding-top:30px;
					.day {
						color:$LinkColor;
						font-size:50px;
						margin-bottom:10px;
						font-family: $font-garamond;
					}
					.month {
						color:$textDark;
						font-family: $font-garamond;
						font-size:30px;
					}
				}
				.dayItemWrapper {
					width:100%;
					.dayItem {
						display: none;
						width:100%;
						margin:10px 0;
						background-color:$midLightGrey;
						position: relative;
						.title {
							
							padding:10px;
							border-bottom:1px solid $textDark;
							/*font-family: $font-garamond;*/
							font-size:20px;
							font-weight:100;
							a {
								color:$LinkColor;
								font-weight:300;
							}
						}
						.dayTime {
							padding:5px 10px;
							font-family: $font-garamond;
							.eventIcon {
								display:inline-block;
								width:16px;
								height:16px;
								background-image:url(../images/alarmClock.svg);
								background-size: contain;
								background-position: center center;
								background-repeat: no-repeat;
								margin-right:20px;
							}
						}
						.categoryColor {
							position: absolute;
							right:0;
							top:0;
							height:100%;
							width:10px;
						}
						
					}
				}
				&::-webkit-scrollbar{
					width:1px;
					height:9px;
				}
				&::-webkit-scrollbar-button:start:decrement,
				&::-webkit-scrollbar-button:end:increment{
					display:block;
					height:0;
					background-color:transparent;
				}
				&::-webkit-scrollbar-track-piece{
					background-color:#eee;
					@include border-radius(0px);
				}
				&::-webkit-scrollbar-thumb{
					background-color: $themeColor;
					@include border-radius(0px);
				}
				&::-webkit-scrollbar-thumb:vertical{
				}
				&::-webkit-scrollbar-thumb:horizontal{
				}
				&::-webkit-scrollbar-thumb:hover{
					background-color: $themeColor;
				}
			}
			&.hidden {
				display:none;
			}
		}
	}
	.categoriesWrapper {
		width:100%;
		text-align:center;
		margin-top:60px;
		.categoryItem {
			padding:20px 20px 20px 60px;
			border:1px solid $textDark;
			display: inline-block;
			margin:0 10px 20px;
			position: relative;
			cursor:pointer;
			.colorChit {
				display: inline-block;
				width:20px;
				height:20px;
				position: absolute;
				left:20px;
				top:50%;
				@include transform(translateY(-50%));
				@include transition($t1)
			}
			.categoryTitle {
				display: inline-block;
				text-transform: uppercase;
				color:$textDark;
				font-weight:400;
				text-align:left;
				/*margin-left:20px;*/
				vertical-align: top;
				min-width:240px;
				@include transition($t1);
				position:relative;
			}
			&:hover {
				.colorChit {
					left:0;
					top:0;
					@include transform(translateY(0%));
					height:100%;
					width:100%;
				}
				.categoryTitle {
					color:#FFFFFF;
				}
			}
			&.active {
				.colorChit {
					left:0;
					top:0;
					@include transform(translateY(0%));
					height:100%;
					width:100%;
				}
				.categoryTitle {
					color:#FFFFFF;
				}
			}
		}
	}
}