.searchWidget {
	display:block;
	background-size:cover;
	background-position:center center;
	padding:50px;
	clear:both;
	.searchWidgetInner {
		margin:12vw auto;
		width:100%;
		max-width:768px;
		text-align:center;
		.searchWidgetTitle {
			color:#FFFFFF;
			font-family:$font-garamond;
			font-size:50px;
			margin-bottom:30px;

		}
		#SearchForm_SearchForm {
			display:inline-block;
			position: relative;
			max-width:unset;
			left:unset;
			top:unset;
			@include transform(translateX(0%) translateY(0%))
			fieldset {
				position: relative;
				padding:0;
				margin:0;
				.field {
					.middleColumn {
						#SearchForm_SearchForm_Search {
							position:relative;
							width:calc(100% - 90px);
							background-color:#FFFFFF;
							color:$LinkColor;
							padding:10px 10px 10px 80px;
							font-size:50px;
							left:unset;
							top:unset;
							@include transform(translateX(0%) translateY(0%))
						}
					}
				}
				#SearchForm_SearchForm_action_results {
					margin-top:0;
					margin-left:0;
					right:unset;
					left:0;
					top:0;
					font-size:0em;
					background-image: url(../images/searchIconGrey.svg);
					background-size: 40% auto;
				}
			}
		}
	}
}