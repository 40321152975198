@media all and (max-width:1680px) {
	.homepageNewsSliderWrapper .outer .homepageNewsSlider .slide .inner .title {
		font-size:18px !important;
	}
}

@media all and (max-width:1280px) {
	body.TransitionPage {
		header {
			.headerImagesWrapper {
				.headImageThree {display:none !important;}
			}
		}
	}
	.homepageNewsSliderWrapper {
		.outer {
			.homepageNewsSlider {
				.newsArticleWrapper {
					
					.slide {
						width:calc((100vw - 100px) / 3 - 2px);
						height:calc((100vw - 100px) / 3 - 2px);
						&:nth-child(4), &:nth-child(5){
							display:none;
						}
					}
				}
			}
		}
	}
	.housepointsWrapper {
		.housepointsInner {
			display:block !important;
			.housepointsItem {
				
				&:nth-child(3n+1){
					width:calc((100vw / 3) - 10px);
					margin-left:0;
				}
				&:nth-child(3n+2){
					width:calc((100vw / 3) - 10px);
				}
				&:nth-child(3n+3){
					width:calc((100vw / 3) - 10px);
					margin-right:0;
				}
				&:last-child {
					margin:5px auto;
					float: none;
				}
			}
		}
	}
	.tutorGroups {
		.tutorGroupsWrapper {
			/*width:70% !important;
			margin-left:30% !important;*/
		}
	}
}

@media all and (max-width:1262px) {
	header{
		nav.main{
			#desktopNavContainer #desktopNav .topLevel{
				padding: 8px 10px !important;
			}
		}
	}
}

@media all and (max-width:1045px) {
	header {
		nav.main {
			.inner.primary {
				display:none !important;
			}
			.navInner {
				display:block !important;
				.headerControls {
					display: inline-block;
					width:auto !important;
				}
				.workWithUsWrapper {
					display: inline-block;
					float:right;
					width:auto !important;
					.backHome {
						display:inline-block !important;
					}
					#MenuIcon {
						display:inline-block !important;
					}
				}
			}
			#desktopNavContainer #desktopNav .topLevel {
				.subMenu {
					.parent {
						max-width:768px !important;
						.stack {
							width:calc((100% / 3) - 24px) !important;
						}
					}
				}
			}
		}
		.headerImagesWrapper {
			    height: calc(100% - 135px) !important;
		}
	}
	#welcomeMessage {
		display:block !important;
		.left,.right {
			width:100% !important;
		}
		.left{
			height:400px;
		}
		.right {
			padding-left:0 !important;
		}
	}
	#homepage-events {
		.widgetsWrapper {
			display:block !important;
			.left, .right {
				width:calc(100% - 120px) !important;
			}
		}
	
	}
	.tutotGroups {
		.tutorsGroupsWrapper {
			width: 80% !important;
    		margin-left: 20% !important;
		}
	}
}

@media all and (max-width: 1000px)  {
	.clearLarge {
		width: 100% !important;
		clear: both;
	}
}
@media all and (max-width: 991px)  {
	.hidden-sm {
		display: none !important;
	}
}

@media all and (max-width:840px) {
	.welcomeLinksWrapper {
		.outer {
			.homepageNewsSlider {
				.newsArticleWrapper {
					display:block !important;
					clear:both;
					.slide {
						&:nth-child(1),
						&:nth-child(2) {
							width: calc((100vw - 100px) / 2 - 5px);
    						height: calc((100vw - 100px) / 2);
    						float:left;
						}
						&:nth-child(3) {
							width: calc((100vw - 100px) - 10px);
    						height: calc((100vw - 100px) / 2);
    						float:left;
						}
						&:nth-child(4),
						&:nth-child(5) {
							width: calc((100vw - 100px) / 2 - 5px);
    						height: calc((100vw - 100px) / 2);
    						float:left;
						}
					}
				}
			}
		}
	}
}

@media all and (max-width: 768px)  {
	.clearSmall {
		clear: both;
		width: 100% !important;
	}

	.tacMid {
		text-align: center !important;
	}
	.leftColumn {
		display:block !important;
		#sidebar {
			float:left;
			width:100% !important;
			max-width:100% !important;
		}
	}
	.centerRight {
		width:calc(100% - 20px) !important;
		padding:0 10px !important;
		h1 {
			font-size:30px !important;
		}
	}
	.centerLeft {
		display: none !important;
	}
	#MobileNavigation {
		width:100%;
		right:-100%;
	}
	header {
		
		nav.main {
			.navInner {
				display:block !important;
				.headerControls, .workWithUsWrapper {
					/*width:calc(50% - 40px) !important;*/
					display:inline-block;
					
					margin-bottom:20px;
				}
				.workWithUsWrapper {
					float:right;
					#MenuIcon {
						display: inline-block;
					}
				}
			}
			.inner.primary {
				display:none !important; 
			}
		}
		.headerImagesWrapper {
			.headImageTwo {
				display:none !important;
			}
		}
	}
	#welcomeMessage {
		.right {
			.rightInner {
				h4 {
					font-size:30px !important;
				}
			}
		}
	}

	.homepageNewsSliderWrapper {
		.titleWrapper {
			h4 {
				display:block !important;
				margin-bottom:20px !important;
				font-size:30px !important;
			}
			.moreNewsTab {
				position: relative !important;
				margin:0 auto 30px auto;
			}
		}
		.outer {
			.homepageNewsSlider {
				.newsArticleWrapper {
					
					.slide {
						width:calc((100vw - 100px) / 2 - 2px);
						height:calc((100vw - 100px) / 2 - 2px);
						&:nth-child(3), &:nth-child(4), &:nth-child(5){
							display:none;
						}
					}
				}
			}
		}
	}

	#homepage-events {
		.widgetsWrapper {
			.left {
				h4 {
					font-size:30px !important;
				}
			}
		}
	}

	.searchWidget {
		.searchWidgetInner {
			#SearchForm_SearchForm {
				fieldset {
					.field {
						.middleColumn {
							#SearchForm_SearchForm_Search {
								    width: calc(100% - 90px) !important;
							}
						}
					}
					#SearchForm_SearchForm_action_results {
						width:80px;
						height:80px;
					}
				}
			}
		}
	}

	#SearchForm_SearchForm fieldset {
		#SearchForm_SearchForm_Search {
			font-size:30px;
			width:240px !important;
		}
		#SearchForm_SearchForm_action_results {
			width: 30px;
    		height: 30px;
    		margin-left:10px;
    		margin-right:10px;
		}
	} 
	footer {
		display:block !important;
		width: calc(100% - 20px) !important;
		padding: 10px !important;
		.footerLeft, .footerRight {
			width:100%;
		}
		.footerRight {
			margin-top:20px;
			#logoIcons {
				text-align:center !important;
			}
			p {
				text-align:center !important;
			}
		}
	}
	.tutorGroups {
		.tutorGroupsWrapper {
			width:100% !important;
			margin-left:0 !important;
			.tutorGroupsTitle {
				text-align:center;
			}
			.tutorGroupsHolder {
				text-align:center;
			}
		}
		&:after {
			background:#FFFFFF !important;
		}
	}
}

@media all and (max-width:640px) {
	/*.housepointsWrapper {
		.housepointsInner {
			.housepointsItem {
				&:nth-child(odd) {
					margin-left:0;
					margin-right:5px;
					width:calc((100vw / 2) - 10px);
				}
				&:nth-child(even) {
					margin-left:5px;
					margin-right:0;
					width:calc((100vw / 2) - 10px);
				}
			}
		}
	}*/

	.housepointsWrapper {
		display:none !important;
	}
	#quickLinks {
		display:none !important;
	}
	.tutorGroups {
		.tutorGroupsWrapper {
			.tutorGroupsHolder {
				a {
					width:100% !important;
					.tutorGroupsItem {
						margin:0 0 20px 0;
					}
				}
			}
		}
	}
}

@media all and (max-width:540px) {
	#homepage-events {
		.widgetsWrapper {
			.left, .right {
				padding:0 !important;
				width:100% !important;
			}
			.right {
				padding-bottom:60px !important;
			}
		}
	}
	header {
		nav.main {
			.navInner {
				.workWithUsWrapper {
					.workWithUs, .backHome {
						margin-bottom:10px;

					}
					#MenuIcon {
						display:block !important;
						margin:0 auto;
					}
				}
			}
		}
	}
}

@media all and (max-width:480px) {
	#welcomeMessage {
		padding:20px !important;
		.right {
			padding:20px !important;
			padding-left:20px !important;
			width:calc(100% - 40px) !important;
		}
	}
	.homepageNewsSliderWrapper {
		padding:50px 10px !important;
		.outer {
			.homepageNewsSlider {
				.newsArticleWrapper {
					display:block !important;
					.slide {
						width:calc((100vw - 20px) - 2px);
						height:calc((100vw - 20px) - 2px);
						&:nth-child(4), &:nth-child(5){
							display:none;
						}
					}
				}
			}
		}
	}

	header {
		nav.main {
			position: relative !important;
			.navInner {
				.headerControls {
					width:calc(100% - 40px) !important;
					text-align:center !important;
				}
				.workWithUsWrapper {
					width:calc(100% - 40px) !important;
					text-align:center !important;
				}
			}
		}
		.tagQuicklinkWrapper {
			.tagline {
				display:none !important;
			}
		}
		#usefulLinks {
			top:65% !important;
		}
	}
	
	#homepage-events {
		padding:20px 10px !important;
		width:calc(100% - 20px) !important;
		.widgetsWrapper {
			.right {
				
				.sliderControls {
					.allEvents {
						right:0 !important;
						width:calc(100% - 90px);
						text-align:center;
					}
					#eventsNext, #eventsPrev {
						bottom:-60px !important;
					}
				}
			}
			
		}
		.categoriesWrapper {
			margin-top:90px !important;
			.categoryItem {
				width: calc(100% - 80px) !important;
				margin:0 20px 20px 0 !important;
			}
		}
	}
	.welcomeLinksWrapper {
		.outer {
			.homepageNewsSlider {
				.newsArticleWrapper {
					display:block !important;
					clear:both;
					.slide {
						&:nth-child(1),
						&:nth-child(2),
						&:nth-child(3),
						&:nth-child(4),
						&:nth-child(5) {
							width: calc((100vw - 100px) - 5px);
    						height: calc((100vw - 100px));
    						float:left;
						}
					}
				}
			}
		}
	}
	.housepointsWrapper {
		.housepointsInner {
			.housepointsItem {
				margin-left:0 !important;
				margin-right:0 !important;
				width:100vw !important;
				.logoIcon {
					max-width:140px;
				}
			}
		}
	}
}

@media all and (max-width: 450px)  {
	header.pageHeader {
		height:100vh !important;
		.headerImage {
			height:calc(100% - 212px);
			position: relative !important;
		}
	}
	body.TransitionPage {
		#welcomeMessage {
			.right {
				.rightInner {
					.welcomeButton {
						min-width:unset !important;
					}
				}
			}
		}
		.welcomeLinksWrapper {
			margin-top:0 !important;
		}
		.searchWidget {
			.searchWidgetInner {
				.searchWidgetTitle {
					font-size:35px !important;
				}
				form {
					fieldset {
						.field.text {
							.middleColumn {
								input.text {
									font-size:35px !important;
								}
							}
						}
					}
				}
			}
		}

	}
	.weekThoughtsWrapper {
		margin-top:60px !important;
		padding:0 0 60px 0 !important;
		.weekThoughtsInner {
			.weekThoughtsItem {
				.TFTW {
					font-size:40px !important;
				}
				.weekThoughtsTitle {
					font-size:30px !important;
				}
			}
		}
	}
	.centerColumn {
		.centerRight {
			table {
				thead {
					tr {
						th {
							padding:10px !important;
							a.prev {
								margin:0 10px 0 0 !important;
							}
							a.next {
								margin:0 0 0 10px !important;
							}
						}
					}
				}
				tr {
					td {
						font-size:13px !important;
						p {
							font-size:13px !important;

						}
						h4 {
							font-size:14px !important;
						}
						strong, span {
							font-size:13px !important;
						}
						ul {
							padding:0 !important;
						}
					}
				}
			}
		}
	}
	body.GalleryHolder {
		.centerColumn {
			.centerRight {
				.galleryImage {
					width:calc(100vw - 20px) !important;
					height:calc(100vw - 20px) !important;
					margin:0 0 5px 0 !important;
					.galleryImageInner {
						height:100%;
						.gallerImageBg {
							width:100% !important;
							height:100% !important;
						}
					}
				}
			}
		}
	}
	.tacSmall {
		text-align: center !important;
	}
	.hiddenSmall {
		display: none !important;
	}
}

@media all and (min-width:1025px) and (max-height:540px) {
	#usefulLinks {
		top:unset !important;
		bottom:0;
		@include transform(translateY(0%) !important)
	}
}












