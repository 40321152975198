.centerColumn {
	.centerRight {
		.galleryWrapper {
			display: inline-block;
			// border:2px solid $lightGrey;
			// padding:20px;
			position: relative;
			margin:0 20px 30px 0;
			overflow:hidden;
			.galleryDecoration {
				.galleryImageSm {
					width:250px;
					height:250px;
					border-radius:25px;
					background-size: cover;
					background-position:center center;
				}

			}
			.galleryTitle {
				text-align: center;
				margin:20px 0;
				border-top:1px solid $LinkColor;
				padding-top:20px;
				@include transition($t1);
			}
			&:after {
				content:'';
				width:100%;
				height:100%;
				background-color: $LinkColor;
				position: absolute;
				left:0;
				bottom:0;
				z-index:-1;
				@include transition($t1);
				opacity:0;
			}
			&:hover {
				.galleryTitle {
					border-top:1px solid #000000;
				}
				&:after {
					opacity:1;
				}
			}
			
		} 

		.galleryImage {
			display: inline-block;
			padding:0;
			position: relative;
			margin:0 5px 5px 0;
			overflow:hidden;
			
			.galleryImageInner {
				.gallerImageBg {
					width:200px;
					height:200px;
					
					background-size: cover;
					background-position:center center;
				}
				.gallerImageText {
					text-align: left;
					padding:10px;
					color:#FFFFFF;
					width:calc(100% - 20px);
					@include transition($t1);
					position: absolute;
					bottom:0;
					left:0;
					@include transform(translateY(calc(100% + 60px)))
					background: -moz-linear-gradient(top, rgba(0,0,0,0.02) 0%, rgba(0,0,0,0.4) 100%);
					background: -webkit-linear-gradient(top, rgba(0,0,0,0.02) 0%,rgba(0,0,0,0.4) 100%);
					background: linear-gradient(to bottom, rgba(0,0,0,0.02) 0%,rgba(0,0,0,0.4) 100%);
					filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#05000000', endColorstr='#66000000',GradientType=0 );
					@include transition($t1)
					.content {
						color:#FFFFFF;
						margin-top:20px;
					}
					
				}
				&:hover .gallerImageText {
					@include transform(translateY(0%))
				}
			}
			
			
			
			&:after {
				content:'';
				width:100%;
				height:100%;
				background-color: $LinkColor;
				position: absolute;
				left:0;
				bottom:0;
				z-index:-1;
				opacity:0;
				@include transition($t1);
			}
			&:hover {
				.galleryImageTitle {
					border-top:1px solid #000000;
				}
				&:after {
					opacity:1;
				}
			}
		}

	}

}