#quickLinks {
	display: block;
	position: relative;
	z-index: 2000;
	overflow: hidden;
	width:100%;
	text-align:center;
	.quicklinkItem {
		display:inline-block;
		padding:12px 60px;
		margin:0 20px 20px 20px;
		position: relative;
		color: #FFFFFF;
		    font-weight: 300;
		    text-transform: uppercase;
		    font-size: 24px;
		a {
			

		}
		.backgroundColor {
			position: absolute;
			left:0;
			top:0;
			width:100%;
			height:100%;
			opacity:0.6;
			z-index:-1;
			@include transition($t1)
		}
		&:hover {
			.backgroundColor {
				opacity:1;
			}
		}
	}
}
